import { useState, useEffect, useCallback, useMemo } from 'react'
import { DragDropContext, Droppable, Draggable, DropResult, DragUpdate } from 'react-beautiful-dnd'
import type { Optional } from '@rio/types'
import { useDispatch } from 'react-redux'
import { trackInternalEvent } from '@rio/tracking'
import ReactDOM from 'react-dom'
import { reject } from 'lodash'
import { useI18n } from 'builder/hooks/useI18n'
import Card from 'builder/components/JobTracking/Card'
import StatusIcon from 'builder/components/JobTrackingStatusIcon'
import {
  ColumnName,
  Job,
  JobCreatePayload,
  JobMovePayload,
  JobDeletePayload,
  actions,
  selectors,
  AutoApplyStatus,
  AutoApplyNotificationState,
} from 'builder/modules/jobTracking'
import { selectors as uiSelectors } from 'builder/modules/ui'
import { actions as renderingActions } from 'builder/modules/rendering'
import { useUser } from 'builder/hooks/useUser'
import { useTypedSelector } from 'builder/hooks/useTypedSelector'
import { usePortalNode } from 'builder/hooks/usePortalNode'
import { useFeaturesConfig } from 'builder/hooks/featureConfig/useFeaturesConfig'
import { useLocalStorage } from 'builder/hooks/useLocalStorage'
import { actions as userActions } from 'builder/modules/user'

import { billingPlanPageURL, goToBillingPlanPage } from 'builder/utils/goToBillingPlanPage'
import { selectors as jobSearchSelectors } from 'builder/modules/jobSearch'
import LimitPaywallModal from '../LimitPaywallModal'
import { JoyRidesJobSearchTrackingSteps } from '../FindJob/JoyRidesJobSearch/JoyRidesJobSearchTrackingSteps'
import QuickAdd from './QuickAdd'
import CardMovedNotification from './CardMovedNotification/CardMovedNotification'
import {
  Content,
  ContentWrapper,
  ContentInner,
  ColumnParent,
  ColumnContainer,
  ColumnHeader,
  ColumnTitle,
  // ColumnCount,
  ColumnContent,
  // AddButton,
  // AddButtonIcon,
  CardNotifiy,
  CardNotifiyContent,
  CardNotifiyTitle,
  CardNotifiyText,
  UpgradeButtonContainer,
  UpgradeButtonIcon,
  // DeleteContainer,
  // DeleteText,
  // DeleteIconContainer,
  // RecycleIcon,
  PlusIcon,
  AddButtonBottom,
  ColumnOuterContainer,
  ColumnTitleJobsCount,
  DisclaimerContainer,
  DisclaimerText,
  DisclaimerCloseBtn,
  DisclaimerCloseIcon,
  AutoApplyAllBtn,
  AutoApplyAllContainer,
  AutoApplyTitleBtn,
  AutoApplyTitleIcon,
  DashboardScroll,
} from './styles'
import ResumeSelection from './ResumeSelection'
import ApplyDialog from './ApplyDialog'
import InterviewMock from './InterviewMock/InterviewMock'
import Clock from './assets/Clock.svg'
import { CardAutoApplyNotification } from './CardMovedNotification/CardAutoApplyNotification'

import { JobTrackerHeader } from './JobTrackerHeader/JobTrackerHeader'

export const DEMO_CHECK_KEY = 'JOB_BOARD_DEMO_SHOWN'
const AUTOAPPLY_3_CREDIT = 3

type BoardProps = {
  jobs: Job[]
  columnNames: ColumnName[]
  onCardCreate: (payload: JobCreatePayload) => void
  onCardClick: (id: number) => void
  move: (payload: JobMovePayload) => void
  reorder: (payload: JobMovePayload) => void
  isFreePlan: Optional<boolean>
  deleteJob: (payload: JobDeletePayload) => void
  handleSetInterviewJob: (selectedJob: Partial<Job> | null) => void
  interviewJob: Partial<Job> | null
  fromDashboard?: boolean
}

const Board = ({
  columnNames: columnNamesProp,
  jobs,
  onCardClick,
  onCardCreate,
  reorder,
  deleteJob,
  move,
  isFreePlan,
  handleSetInterviewJob,
  interviewJob,
  fromDashboard,
}: BoardProps) => {
  const user = useUser()
  const dispatch = useDispatch()

  const isAutoApplyEnabled = useFeaturesConfig().features?.autoApply
  const columnNames = isAutoApplyEnabled
    ? columnNamesProp
    : reject(columnNamesProp, item => item === ColumnName.auto_apply)

  const [addingTo, setAddingTo] = useState<ColumnName | null>(null)
  const [lastUpdateTime, setLastUpdate] = useState(() => Date.now())
  const [movingDragId, setMovingDragId] = useState()
  const [isDragging, setDragging] = useState(false)
  const [draggingJob, setDragggingJob] = useState<Job | undefined>(undefined)
  const [hoveredDroppableId, setHoveredDroppableId] = useState<ColumnName | null>(null)
  const [limitPaywallOpen, setLimitPaywallOpen] = useState<boolean>(false)
  const [isNotificationVisible, setIsNotificationVisible] = useState<boolean>(false)
  const [autoApplyNotification, setAutoApplyNotification] =
    useState<AutoApplyNotificationState | null>(null)
  const [AAColDisclaimer, setAAColDisclaimer] = useLocalStorage(
    `auto-apply-disclaimer-${user?.id}`,
    false,
  )
  const [wishlistColDisclaimer, setWishlistColDisclaimer] = useLocalStorage(
    `wishlist-disclaimer-${user?.id}`,
    false,
  )
  const portalNode = usePortalNode()
  const FOUR_SECOND = 4000

  const { i18n } = useI18n()

  const resumeSelection = useTypedSelector(selectors.resumeSelection)
  const applyTrackingDialog = useTypedSelector(selectors.selectApplyTrackingDialog)
  const canInstallExtension = useTypedSelector(selectors.canInstallExtension)
  const isSideMenuOpen = useTypedSelector(uiSelectors.isSideMenuOpen)

  // const scrollRef = useHorizontalScroll()
  // const dragScrollRef = useDragScroll()
  const handleNotification = () => {
    setIsNotificationVisible(true)
    setTimeout(() => {
      setIsNotificationVisible(false)
    }, FOUR_SECOND)
  }

  const handleAutoApplyNotification = ({
    source,
    destination,
    job,
  }: {
    source: ColumnName
    destination: ColumnName
    job: Job
  }) => {
    if (source !== ColumnName.auto_apply && destination !== ColumnName.auto_apply) {
      return
    }
    if (source !== ColumnName.auto_apply && destination === ColumnName.auto_apply) {
      if (!job.autoApplyStatus || job.autoApplyStatus === 'processing') {
        setAutoApplyNotification(AutoApplyNotificationState.processing)
      } else {
        setAutoApplyNotification(AutoApplyNotificationState.added)
      }
    } else if (source === ColumnName.auto_apply && destination !== ColumnName.auto_apply) {
      setAutoApplyNotification(AutoApplyNotificationState.removed)
    }

    setTimeout(() => {
      setAutoApplyNotification(null)
    }, FOUR_SECOND)
  }

  useEffect(() => {
    const id = setInterval(() => setLastUpdate(Date.now()), 30000)
    return () => clearInterval(id)
  }, [])

  useEffect(() => {
    dispatch(actions.fetchResumeListRequest())
  }, [dispatch])

  const columns = useMemo(() => {
    return columnNames.reduce((result, name) => {
      result[name] = jobs.filter(job => job.status === name)
      return result
    }, {} as Record<ColumnName, Job[]>)
  }, [columnNames, jobs])

  const handleUpgrade = () => {
    trackInternalEvent('click_upgrade_notification')
  }

  const jobsCount = useMemo(
    () =>
      Object.assign(
        {},
        ...Object.values(ColumnName).map(status => ({
          [status]: jobs.filter(job => job.status === status).length,
        })),
      ),
    [jobs],
  )

  const maxLimitCondition = useCallback(
    (currentColumn: string | undefined, isNotification: boolean) => {
      const appliedLength = columns.applied.length + 1
      if (currentColumn === 'applied' && isFreePlan && appliedLength > 3) {
        if (isNotification) {
          return (
            <CardNotifiy>
              <CardNotifiyContent>
                <CardNotifiyTitle>
                  {i18n.t(`builder.job_tracking.board.max_limit.title`)}
                </CardNotifiyTitle>
                <CardNotifiyText>
                  {i18n.t(`builder.job_tracking.board.max_limit.subtitle`)}
                </CardNotifiyText>
              </CardNotifiyContent>
              <UpgradeButtonContainer
                onClick={() => handleUpgrade()}
                href={billingPlanPageURL({ upgradeURL: user?.billingInfo?.upgradeUrl })}
              >
                <UpgradeButtonIcon />
                {i18n.t(`builder.job_tracking.board.max_limit.upgrade`)}
              </UpgradeButtonContainer>
            </CardNotifiy>
          )
        } else {
          setLimitPaywallOpen(true)
          return true
        }
      }
    },
    [user, columns, isFreePlan],
  )

  const onDragStart = useCallback(
    ({ draggableId }) => {
      setMovingDragId(draggableId)
      setDragging(true)
      setDragggingJob(jobs.find(({ id }) => id === Number(draggableId)))
    },
    [jobs, movingDragId],
  )

  const autoApplyLimit = () => {
    if (typeof user?.scopes?.autoApply === 'object') {
      return user?.scopes?.autoApply.creditCount
    }
    return 0
  }

  const autoApplyUsedCreditCount = () => {
    if (typeof user?.scopes?.autoApply === 'object') {
      return user?.scopes?.autoApply.creditCountUsed
    }
    return 0
  }

  const isAutoApplyLimitReached = (destination: ColumnName, count = 1) => {
    if (destination !== ColumnName.auto_apply) return

    const usedCredits = autoApplyUsedCreditCount() + count
    if (autoApplyLimit() && usedCredits > autoApplyLimit()) {
      if (autoApplyLimit() === AUTOAPPLY_3_CREDIT) {
        dispatch(renderingActions.showRDUpsellModal())
        return true
      } else {
        dispatch(renderingActions.showRDNotEnoughCreditsModal())
        return true
      }
    }
  }

  const redirectToBillingPlans = (destination: ColumnName) => {
    if (destination !== ColumnName.auto_apply) return

    if (isAutoApplyEnabled && !user?.scopes?.autoApply && isFreePlan) {
      goToBillingPlanPage({ referrerPage: 'auto-apply', onSuccessRedirect: window.location.href })
      return true
    }
  }

  const onDragEnd = useCallback(
    ({ source, destination, draggableId }: DropResult) => {
      setDragging(false)
      if (destination?.droppableId === 'deleted') {
        deleteJob({ id: parseInt(draggableId) })
        setHoveredDroppableId(null)
        setTimeout(() => dispatch(userActions.fetchUserRequest({ silent: true })), 500)
        return
      }

      // Redirect to billing plans if user has auto-apply A/B test enabled and it's on free plan
      if (redirectToBillingPlans(destination?.droppableId as ColumnName)) {
        return
      }

      // Check for the limit condition on Dragging to Applied Column
      if (maxLimitCondition(destination?.droppableId, false)) return

      // Check for auto-apply credit on dragging to auto apply column
      if (isAutoApplyLimitReached(destination?.droppableId as ColumnName)) {
        return
      }

      // dropped outside the list
      if (!destination) return

      if (source.droppableId === destination.droppableId) {
        return reorder({ source, destination } as JobMovePayload)
      }

      move({ source, destination, jobId: Number(draggableId) } as JobMovePayload)
      setHoveredDroppableId(null)
      handleNotification()

      const job = jobs.find(job => Number(job.id) === Number(draggableId))
      if (job) {
        handleAutoApplyNotification({
          source: source.droppableId as ColumnName,
          destination: destination.droppableId as ColumnName,
          job,
        })
      }
      setTimeout(() => dispatch(userActions.fetchUserRequest({ silent: true })), 500)
    },
    [move, reorder, maxLimitCondition, deleteJob, dispatch],
  )

  const onDragUpdate = ({ destination }: DragUpdate) => {
    if (isDragging && destination) {
      setHoveredDroppableId(destination.droppableId as ColumnName)
    }
  }

  const handleApplyToAll = useCallback(() => {
    const wishlistJobs = jobs.filter(job => job.status === ColumnName.wishlist)
    if (!wishlistJobs.length) {
      return
    }

    // Redirect to billing plans if user has auto-apply A/B test enabled and it's on free plan
    if (redirectToBillingPlans(ColumnName.auto_apply)) {
      return
    }

    if (isAutoApplyLimitReached(ColumnName.auto_apply, wishlistJobs.length)) {
      return
    }

    const moved = jobs.map(job =>
      job.status === ColumnName.wishlist
        ? {
            ...job,
            status: ColumnName.auto_apply,
            autoApplyStatus: 'processing' as AutoApplyStatus,
          }
        : job,
    )
    dispatch(actions.setJobsData(moved))
    reorder({
      source: {
        index: 0,
        droppableId: ColumnName.wishlist,
      },
      destination: {
        droppableId: ColumnName.auto_apply,
        index: 0,
      },
    })
    handleAutoApplyNotification({
      source: ColumnName.wishlist,
      destination: ColumnName.auto_apply,
      job: wishlistJobs[0],
    })
    wishlistJobs.forEach(() => {
      trackInternalEvent('auto_apply_for_job', {
        label: 'job_tracker',
        parameter: 'job_card',
      })
      trackInternalEvent('change_job_tracking_card_status', {
        status: ColumnName.auto_apply,
      })
    })
    setTimeout(() => dispatch(userActions.fetchUserRequest({ silent: true })), 500)
  }, [jobs, dispatch, reorder])

  const handleAutoApplyCard = useCallback(
    (job: Job) => {
      // Redirect to billing plans if user has auto-apply A/B test enabled and it's on free plan
      if (redirectToBillingPlans(ColumnName.auto_apply)) {
        return
      }

      if (isAutoApplyLimitReached(ColumnName.auto_apply)) {
        return
      }

      const payload = {
        source: {
          droppableId: job.status,
          index: job.positionNumber,
        },
        destination: {
          droppableId: ColumnName.auto_apply,
          index: 0,
        },
        jobId: Number(job.id),
      }
      dispatch(actions.moveRequest(payload))

      handleAutoApplyNotification({
        source: ColumnName.wishlist,
        destination: ColumnName.auto_apply,
        job,
      })
    },
    [jobs, dispatch],
  )

  const onQuickAdd = (fields: JobCreatePayload) => {
    // Check for the limit condition on Adding Job to Applied Column
    if (maxLimitCondition(addingTo?.toString(), false)) return

    if (addingTo) onCardCreate({ ...fields, status: addingTo })
    setAddingTo(null)
  }

  const handleApplyOk = () => {
    setHoveredDroppableId(null)
    dispatch(actions.setApplyTrackingDialog(null))

    // Check for the limit condition on Adding Job to Applied Column
    if (maxLimitCondition('applied', false)) return

    move(applyTrackingDialog?.moveData as JobMovePayload)
  }

  const handleApplyCancel = () => {
    setHoveredDroppableId(null)
    dispatch(actions.setApplyTrackingDialog(null))
    trackInternalEvent('close_apply_banner', { label: 'tracker' })
  }

  const isDropDisabled = useCallback(
    (columnName: ColumnName) => {
      if (columnName === ColumnName.recommended) {
        return true
      }
      // Auto Apply error can be moved freely
      if (draggingJob?.autoApplyStatus === 'error') {
        return false
      }
      // Auto Apply processing or unprocessed jobs can only go back to wishlist.
      if (columnName !== ColumnName.wishlist && draggingJob?.status === ColumnName.auto_apply) {
        return true
      }
      // Don't allow already auto applied job into the Auto Apply column.
      if (columnName === ColumnName.auto_apply && draggingJob?.autoApplyStatus === 'auto_applied') {
        return true
      }

      return false
    },
    [draggingJob],
  )
  const autoApplyBubbles = useTypedSelector(jobSearchSelectors.autoApplyBubbles)

  return (
    <>
      {!fromDashboard && (
        <>
          <JoyRidesJobSearchTrackingSteps />
          <Content fromDashboard={fromDashboard}>
            <JobTrackerHeader />
          </Content>
        </>
      )}
      <DragDropContext onDragUpdate={onDragUpdate} onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <Content fromDashboard={fromDashboard}>
          <ContentWrapper>
            <ContentInner fromDashboard={fromDashboard}>
              {columnNames.map(name => {
                if (name !== 'deleted') {
                  return (
                    <ColumnOuterContainer key={name}>
                      <div id={name}>
                        <ColumnHeader status={name}>
                          <StatusIcon status={name} />
                          <ColumnTitle status={name}>
                            {i18n.t(`builder.job_tracking.columns.${name}`)}
                            <ColumnTitleJobsCount>{jobsCount[name]}</ColumnTitleJobsCount>
                            {isAutoApplyEnabled && name === ColumnName.wishlist && (
                              <>
                                <AutoApplyTitleBtn onClick={() => handleApplyToAll()}>
                                  {i18n.t(`builder.job_tracking.auto_apply.disclaimer.button`)}
                                </AutoApplyTitleBtn>
                                <AutoApplyTitleIcon src={Clock} />
                              </>
                            )}
                          </ColumnTitle>
                          <span>
                            {!(
                              isFreePlan &&
                              name === ColumnName.applied &&
                              columns[name].length >= 3
                            ) &&
                              name !== ColumnName.recommended && (
                                <PlusIcon status={name} onClick={() => setAddingTo(name)} />
                              )}
                          </span>
                        </ColumnHeader>
                        <Droppable
                          key={name}
                          droppableId={name}
                          // ignoreContainerClipping={true}
                          isDropDisabled={isDropDisabled(name)}
                        >
                          {provided => (
                            <ColumnParent
                              isDraggedOver={hoveredDroppableId === name}
                              isDragging={isDragging}
                              status={name}
                              ref={provided.innerRef}
                            >
                              <ColumnContainer
                                isDraggedOver={hoveredDroppableId === name}
                                isDragging={isDragging}
                                status={name}
                              >
                                {/* <AddButton tabIndex={index + 1} onClick={() => setAddingTo(name)}>
                            <AddButtonIcon />
                            <span>{i18n.t('builder.job_tracking.add_job')}</span>
                          </AddButton> */}

                                <ColumnContent
                                  data-testid={name}
                                  canInstallExtension={canInstallExtension}
                                  fromDashboard={fromDashboard}
                                >
                                  {!autoApplyBubbles.running &&
                                    isAutoApplyEnabled &&
                                    !AAColDisclaimer &&
                                    name === ColumnName.auto_apply && (
                                      <DisclaimerContainer>
                                        <DisclaimerText column={name}>
                                          {i18n.t(
                                            `builder.job_tracking.auto_apply.disclaimer.auto_apply`,
                                          )}
                                        </DisclaimerText>
                                        <DisclaimerCloseBtn
                                          onClick={() => setAAColDisclaimer(true)}
                                        >
                                          <DisclaimerCloseIcon column={name} />
                                        </DisclaimerCloseBtn>
                                      </DisclaimerContainer>
                                    )}
                                  {!autoApplyBubbles.running &&
                                    isAutoApplyEnabled &&
                                    !wishlistColDisclaimer &&
                                    name === ColumnName.wishlist && (
                                      <>
                                        <DisclaimerContainer>
                                          <DisclaimerText column={name}>
                                            {i18n.t(
                                              `builder.job_tracking.auto_apply.disclaimer.wishlist`,
                                            )}
                                          </DisclaimerText>
                                          <DisclaimerCloseBtn
                                            onClick={() => setWishlistColDisclaimer(true)}
                                          >
                                            <DisclaimerCloseIcon column={name} />
                                          </DisclaimerCloseBtn>
                                        </DisclaimerContainer>
                                        <AutoApplyAllContainer>
                                          <AutoApplyAllBtn onClick={() => handleApplyToAll()}>
                                            {i18n.t(
                                              `builder.job_tracking.auto_apply.disclaimer.button`,
                                            )}
                                          </AutoApplyAllBtn>
                                        </AutoApplyAllContainer>
                                      </>
                                    )}

                                  {columns[name].map((item, index) => (
                                    <Draggable
                                      // isDragDisabled={window?.innerWidth <= 780}
                                      key={item.id}
                                      draggableId={item.id.toString()}
                                      index={index}
                                    >
                                      {(provided, snapshot) => (
                                        <Card
                                          {...{
                                            item,
                                            lastUpdateTime,
                                            provided,
                                            snapshot,
                                            isDragging,
                                          }}
                                          onClick={onCardClick}
                                          handleSetInterviewJob={handleSetInterviewJob}
                                          handleAutoApplyCard={
                                            (name === ColumnName.wishlist ||
                                              name === ColumnName.recommended) &&
                                            isAutoApplyEnabled
                                              ? handleAutoApplyCard
                                              : undefined
                                          }
                                        />
                                      )}
                                    </Draggable>
                                  ))}
                                  {addingTo === name && (
                                    <QuickAdd
                                      onSubmit={onQuickAdd}
                                      onCancel={() => setAddingTo(null)}
                                      columns={columns[name]}
                                    />
                                  )}
                                  {!(
                                    isFreePlan &&
                                    name === 'applied' &&
                                    columns[name].length >= 3
                                  ) &&
                                    name !== 'recommended' && (
                                      <AddButtonBottom
                                        status={name}
                                        onClick={() => setAddingTo(name)}
                                      >
                                        <PlusIcon status={name} /> <>Add Job</>
                                      </AddButtonBottom>
                                    )}
                                  {provided.placeholder}
                                  {maxLimitCondition(name, true)}
                                </ColumnContent>
                              </ColumnContainer>
                            </ColumnParent>
                          )}
                        </Droppable>
                      </div>
                    </ColumnOuterContainer>
                  )
                } else return null
                // } else {
                //   return (
                //     <Droppable key={name} droppableId={name} ignoreContainerClipping={true}>
                //       {provided => (
                //         <DeleteContainer ref={provided.innerRef} isDragging={isDragging}>
                //           <DeleteText
                //             isDraggedOver={hoveredDroppableId === name}
                //             isDragging={isDragging}drop
                //           >
                //             Drop it here to delete
                //           </DeleteText>
                //           <DeleteIconContainer
                //             isDraggedOver={hoveredDroppableId === name}
                //             isDragging={isDragging}
                //           >
                //             <RecycleIcon />
                //           </DeleteIconContainer>
                //         </DeleteContainer>
                //       )}
                //     </Droppable>
                //   )
                // }
              })}
              {fromDashboard && <DashboardScroll isSideMenuOpen={isSideMenuOpen} />}
            </ContentInner>
          </ContentWrapper>
        </Content>
      </DragDropContext>
      {limitPaywallOpen && (
        <LimitPaywallModal
          limitPaywallOpen={limitPaywallOpen}
          setLimitPaywallOpen={setLimitPaywallOpen}
          from="tracker"
        />
      )}
      {applyTrackingDialog && (
        <ApplyDialog handleApplyOk={handleApplyOk} handleApplyCancel={handleApplyCancel} />
      )}
      {isNotificationVisible && <CardMovedNotification />}
      {autoApplyNotification && <CardAutoApplyNotification message={autoApplyNotification} />}
      {resumeSelection && <ResumeSelection onClick={onCardClick} />}
      {ReactDOM.createPortal(
        <InterviewMock
          visible={!!interviewJob}
          selectedJob={interviewJob}
          onClose={() => handleSetInterviewJob(null)}
          trackingLabel="job_tracker"
        />,
        portalNode,
      )}
    </>
  )
}

export default Board
